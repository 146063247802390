'use client'

import { useEffect } from 'react'

import { useTranslations } from 'next-intl'

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  const t = useTranslations('common.GlobalError')

  // TODO: SB-623 log error
  useEffect(() => {
    console.error(error)
  }, [error])

  return (
    <html lang="nb">
      <head>
        <title>{t('headTitle')}</title>
      </head>
      <body>
        <main>
          <h1>{t('pageTitle')}</h1>
          <p>{t('body')}</p>
          <pre>
            {error.name}
            <br />
            {error.message}
          </pre>
          <button
            onClick={() => {
              reset()
            }}
          >
            {t('retry')}
          </button>
        </main>
      </body>
    </html>
  )
}
